














import Vue from 'vue'
import { Prop, Component, Mixins } from 'vue-property-decorator'
import { ModalFunctions } from '~/mixins/modals'

// @ts-ignore
import VueClipboard from 'vue-clipboard2'
import { IS_ELITEPAGE, IS_MARKETPLACE } from '~/const/environment'
Vue.use(VueClipboard)

@Component
export default class ShareBeat extends Mixins(ModalFunctions) {
    @Prop({ type: Object }) beat!: IBeat

    copied = false

    get linkUrl() {
        if (IS_MARKETPLACE) return `${window.location.origin}/beat/${this.beat.id}`
        else if (IS_ELITEPAGE) return `${window.location.origin}/${this.beat.producer.slug}/beats/${this.beat.id}`
    }

    doCopy() {
        // @ts-ignore
        this.$copyText(this.linkUrl)
        this.copied = true
    }
}
