var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modal__container modal__container--overlay" },
    [
      _c(
        "div",
        { staticClass: "modalDialog" },
        [
          _c("icon", {
            staticClass: "modalcloseButton",
            attrs: { name: "close" },
            nativeOn: {
              click: function ($event) {
                return _vm.closeModal.apply(null, arguments)
              },
            },
          }),
          _c("div", { staticClass: "modalContent" }, [
            _c("div", { staticClass: "modalContent__body" }, [
              _c("h3", { staticClass: "modal__title" }, [
                _vm._v("Share this beat"),
              ]),
              _c("div", { staticClass: "formGroup" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.linkUrl,
                      expression: "linkUrl",
                    },
                  ],
                  ref: "linkUrl",
                  staticClass:
                    "formInput formInput--rounded formInput--fullWidth",
                  attrs: { name: "beat", type: "text" },
                  domProps: { value: _vm.linkUrl },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.linkUrl = $event.target.value
                    },
                  },
                }),
                _c(
                  "p",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.copied,
                        expression: "copied",
                      },
                    ],
                    staticClass: "formInput__hint",
                  },
                  [_vm._v("Link copied to clipboard")]
                ),
              ]),
              _c("div", { staticClass: "form__submit" }, [
                _c(
                  "a",
                  {
                    staticClass: "btn btn--fullWidth btn--primary",
                    on: { click: _vm.doCopy },
                  },
                  [_vm._v(_vm._s(_vm.copied ? "COPIED" : "COPY"))]
                ),
              ]),
            ]),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }